/* Components/Navbar.css */
.navbar {
    position: fixed;
    top: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: var(--bg-color);
    z-index: 100;
  }
  
  .navbar-link {
    color: #666;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.2s ease;
    position: relative;
  }
  
  .navbar-link:hover {
    color: #212529;
  }