.sidebar-container {
  position: fixed;
  top: 20px;
  left: -400px;
  width: 360px;
  height: calc(100vh - 40px);
  background: var(--bg-color);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 3;
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
}

.sidebar-container.show {
  left: 20px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background: var(--bg-color);
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
}

.sidebar-header {
  padding: 16px;
  border-bottom: 1px solid var(--dark-underlay);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-header h2 {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
  margin: 0;
}

.sidebar-content {
  padding: 16px;
  overflow-y: auto;
  flex-grow: 1;
}

/* Custom scrollbar styles */
.sidebar-content::-webkit-scrollbar {
  width: 8px;
}

.sidebar-content::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb-color);
  border-radius: 4px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.collapse-button {
  background: transparent;
  border: none;
  color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
  transition: color 0.2s ease;
}

.collapse-button:hover {
  color: var(--button-hover-color);
}