.profile-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--button-color);
    border: none;
    color: var(--text-color-button);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-button:hover {
    background: var(--button-hover-color);
}

.profile-icon {
    width: 14px;
    height: 14px;
    fill: currentColor;
}
  