:root {
    --bg-color: #FCFCF9;
    --text-color: #13343B;
    --button-color: #13343B;
    --buton-content-color: #FCFCF9;
    --dark-underlay: #F3F3EE;
    --button-hover-color: #1A4D5C;
    --searchbar-bg-color: #FFFFFF;
    --scroll-thumb-color: #E0E0DC;
    --sidebar-button-hover: #F3F3EE;
  }
  
  [data-theme='dark'] {
    --bg-color: #13343B;
    --text-color: #FCFCF9;
    --button-color: #1A4D5C;
    --buton-content-color: #FCFCF9;
    --dark-underlay: #0A191C;
    --button-hover-color: #226577;
    --searchbar-bg-color: #FCFCF9;
    --scroll-thumb-color: #1A4D5C;
    --sidebar-button-hover: #1A4D5C;;
  }
  
 