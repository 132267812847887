* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

body {
    overflow: hidden;
    background: #f8f9fa;
    color: #1a1a1a;
}

:root {
    --bg-color: #f8f9fa;
    --text-color: #1a1a1a;
    --button-color: #adb5bd;
    --button-hover-color: #868e96;
    --dark-underlay: #dee2e6;
    --searchbar-bg-color: #ffffff;
    --sidebar-button-hover: #e9ecef;
    --scroll-thumb-color: #dee2e6;
    --text-color-button: #ffffff;
}

/* Dark theme variables */
[data-theme='dark'] {
    --bg-color: #1a1a1a;
    --text-color: #f8f9fa;
    --button-color: #adb5bd;
    --button-hover-color: #868e96;
    --dark-underlay: #343a40;
    --searchbar-bg-color: #212529;
    --sidebar-button-hover: #343a40;
    --scroll-thumb-color: #495057;
}
