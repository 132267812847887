/* Home.css */
.Start {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--bg-color);
  color: var(--text-color);
  transition: all 0.2s ease;
}

body {
  background-color: var(--bg-color); /* Correctly applying CSS variable */
  overflow: hidden;
  transition: background-color 0.3s ease;
  z-index: -10;
}

.background-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.searchbar-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin-bottom: 12px;
  z-index: 1;
}

.elementar-particles {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.explorebutton-container {
  margin-top: 12px;
  z-index: 1;
}

.navbar-container {
  z-index: 2;
}
