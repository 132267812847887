.explore-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: var(--bg-color);
  color: var(--text-color);
  transition: all 0.2s ease;
  z-index: 0;
}

.explore-navbar-container {
  height: 60px;
  background: var(--bg-color);
}

.main-content {
  flex: 1;
  margin-left: 280px;
  transition: margin-left 0.3s ease;
  padding: 20px;
}

.sidebar.collapsed + .main-content {
  margin-left: 0;
}

.info-button {
    position: fixed;
    top: 20px;
    z-index: 1;
}

.info-button.menu-button {
    left: 20px;
}

.info-toggle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: transparent;
    border: none;
    color: #adb5bd;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    padding: 0;
}

.info-toggle .material-icons {
    font-size: 28px;
    font-weight: 300;
}

.info-toggle:hover {
    color: #495057;
}

.menu-toggle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--button-color);
    border: 0;
    outline: none;
    color: var(--text-color-button);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    padding: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.menu-toggle:focus {
    outline: none;
}

.menu-toggle:hover {
    background: var(--button-hover-color);
}

.sidebar-container {
    z-index: 10;
}

.info-panel {
    z-index: 10;
}

