@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');


.sidebar-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    background: transparent;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: var(--text-color);
}

.sidebar-button:hover {
    background: var(--sidebar-button-hover);
}

.sidebar-button-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.button-text {
    font-size: 14px;
    font-weight: 500;
}