.sidesearchbar-container {
    margin: 20px 0;
    padding: 0 16px;
}

.sidesearchbar {
    width: 100%;
    height: 36px;
    background: var(--searchbar-bg-color);
    border: 1px solid var(--dark-underlay);
    border-radius: 18px;
    padding: 0 16px;
    font-size: 14px;
    color: #495057;
    transition: all 0.2s ease;
}

.sidesearchbar::placeholder {
    color: #adb5bd;
    font-weight: 400;
}

.sidesearchbar:focus {
    outline: none;
    border-color: var(--button-color);
    box-shadow: 0 0 0 3px rgba(173, 181, 189, 0.15);
}

/* Remove the default clear (x) button in modern browsers */
.sidesearchbar::-webkit-search-cancel-button,
.sidesearchbar::-ms-clear {
    display: none;
}
  