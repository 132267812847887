.explore-button {
    height: 44px;
    padding: 0 32px;
    background: transparent;
    border: none;
    border-radius: 22px;
    color: #495057;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
}

.explore-button::before {
    content: '';
    position: absolute;
    inset: -1px;
    background: linear-gradient(
        77deg,
        #FF0000 0%,
        #FF8C00 15%,
        #FFD700 30%,
        #32CD32 45%,
        #00BFFF 60%,
        #8A2BE2 75%,
        #FF0000 90%,
        #FF0000 100%
    );
    border-radius: 22px;
    z-index: -1;
    background-size: 200% 100%;
    animation: gradientFlow 3s linear infinite;
    transition: all 0.3s ease;
    filter: blur(0.5px);
}

.explore-button::after {
    content: '';
    position: absolute;
    inset: 1px;
    background: var(--bg-color);
    border-radius: 21px;
    z-index: -1;
    transition: all 0.3s ease;
}

.explore-button:hover {
    transform: scale(1.02);
    color: white;
}

.explore-button:hover::after {
    opacity: 0;
}

@keyframes gradientFlow {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.explore-button:active {
    transform: scale(0.98);
}
  