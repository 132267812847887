* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: #f8f9fa;
}

.signup-box {
    background: white;
    padding: 40px;
    border-radius: 12px;
    width: 360px;
    position: relative;
    z-index: 0;
}

.signup-box::before {
    content: '';
    position: absolute;
    inset: -1px;
    background: linear-gradient(
        60deg,
        #FF0000 0%,
        #FF8C00 15%,
        #FFD700 30%,
        #32CD32 45%,
        #00BFFF 60%,
        #8A2BE2 75%,
        #FF0000 90%,
        #FF0000 100%
    );
    border-radius: 12px;
    z-index: -1;
    background-size: 200% 100%;
    animation: gradientFlow 3s linear infinite;
    filter: blur(0.5px);
}

.signup-box::after {
    content: '';
    position: absolute;
    inset: 0;
    background: white;
    border-radius: 12px;
    z-index: -1;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.signup-box h2 {
    font-size: 14px;
    font-weight: 500;
    color: #666;
    margin-bottom: 32px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.input-group {
    margin-bottom: 24px;
}

.input-group label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.input-group input {
    width: 100%;
    height: 32px;
    padding: 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid #dee2e6;
    border-radius: 0;
    font-size: 14px;
    color: #495057;
    transition: all 0.2s ease;
}

.input-group input:focus {
    outline: none;
    border-bottom-color: #adb5bd;
    background: transparent;
}

.input-group input::placeholder {
    color: #adb5bd;  /* Lighter color for placeholder text */
    opacity: 1;  /* Ensure consistent opacity across browsers */
}

.code-input {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.digit-input {
    width: 32px;
    height: 32px;
    padding: 0;
    border: 1px solid #dee2e6;
    background: #f8f9fa;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #495057;
    transition: all 0.2s ease;
}

.digit-input:focus {
    outline: none;
    border-color: #adb5bd;
    background: white;
}

.digit-input::placeholder {
    color: #adb5bd;
    opacity: 1;
}

.signup-button {
    width: 100%;
    height: 32px;
    border: 1px solid #dee2e6;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: white;
    color: #495057;
    margin-top: 24px;
    position: relative;
    overflow: hidden;
}

.signup-button::before {
    content: '';
    position: absolute;
    inset: -1px;
    background: linear-gradient(
        60deg,
        #FF0000 0%,
        #FF8C00 15%,
        #FFD700 30%,
        #32CD32 45%,
        #00BFFF 60%,
        #8A2BE2 75%,
        #FF0000 90%,
        #FF0000 100%
    );
    border-radius: 16px;
    z-index: -1;
    background-size: 200% 100%;
    animation: gradientFlow 3s linear infinite;
    transition: all 0.3s ease;
    filter: blur(0.5px);
    opacity: 0;
}

.signup-button:hover::before {
    opacity: 1;
}

.signup-button:hover {
    color: white;
    border-color: transparent;
    transform: scale(1.02);
}

@keyframes gradientFlow {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -200% 0;
    }
}
  