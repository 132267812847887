.searchbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45vw;
    min-width: 300px;
}

.searchbar {
    width: 100%;
    height: 44px;
    background: var(--searchbar-bg-color);
    border: 1px solid var(--dark-underlay);
    border-radius: 22px;
    padding: 0 16px;
    font-size: 16px;
    color: #495057;
    transition: all 0.2s ease;
}

.searchbar::placeholder {
    color: #adb5bd;
    font-weight: 400;
}

.searchbar:focus {
    outline: none;
    border-color: var(--button-color);
    box-shadow: 0 0 0 3px rgba(173, 181, 189, 0.15);
}

/* Remove the default clear (x) button in modern browsers */
.searchbar::-webkit-search-cancel-button,
.searchbar::-ms-clear {
    display: none;
}
  