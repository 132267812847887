/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* Copy all styles from Moleculesstyles.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Rest of the styles from Moleculesstyles.css... */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

body {
    overflow: hidden;
    background: #f8f9fa;
    color: #1a1a1a;
}

#particleCanvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    cursor: grab;
}

#particleCanvas:active {
    cursor: grabbing;
}

.controls-panel {
    position: fixed;
    top: 50%;
    right: -70px;
    transform: translateY(-50%);
    z-index: 2;
    min-width: 280px;
}

.control-group {
    margin-bottom: 20px;
}

.control-group:last-child {
    margin-bottom: 0;
}

.control-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #666;
}

.number-control {
    display: flex;
    align-items: center;
    gap: 0;
}

.number-control button {
    width: 32px;
    height: 32px;
    background: #f8f9fa;
    color: #1a1a1a;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    border: 1px solid #dee2e6;
}

.number-control button:hover {
    background: #e9ecef;
}

.number-control button.decrease {
    border-radius: 8px 0 0 8px;
    border-right: none;
}

.number-control button.increase {
    border-radius: 0 8px 8px 0;
    border-left: none;
}

.number-control input {
    width: 60px;
    height: 32px;
    text-align: center;
    border: 1px solid #dee2e6;
    background: #f8f9fa;
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
    border-radius: 0;
}

.number-control input:focus {
    outline: none;
    border-color: #adb5bd;
}

.number-control input:focus + button,
.number-control input:focus ~ button {
    border-color: #adb5bd;
}

.element-selector {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
}

.element-display {
    display: inline-block;
    font-size: 28px;
    font-weight: 500;
    color: #1a1a1a;
    letter-spacing: 0.5px;
}

.dropdown-container {
    position: relative;
}

.dropdown-button {
    background: transparent;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 4px 8px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    height: 100%;
    transition: color 0.2s ease;
}

.dropdown-button:hover {
    color: #1a1a1a;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border: 1px solid #dee2e6;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.08);
    width: 240px;
    max-height: 400px;
    overflow: hidden;
}

.dropdown-content.show {
    display: block;
    animation: dropdownFade 0.2s ease;
}

@keyframes dropdownFade {
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(-8px);
    }
    to {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }
}

#elementSearch {
    width: 100%;
    padding: 16px;
    border: none;
    border-bottom: 1px solid #f1f3f5;
    position: sticky;
    top: 0;
    background: white;
    font-size: 14px;
    color: #1a1a1a;
    transition: background 0.2s ease;
}

#elementSearch::placeholder {
    color: #adb5bd;
}

#elementSearch:focus {
    outline: none;
    background: #f8f9fa;
}

.dropdown-list {
    max-height: 350px;
    overflow-y: auto;
    padding: 8px 0;
}

/* Custom scrollbar for the dropdown list */
.dropdown-list::-webkit-scrollbar {
    width: 8px;
}

.dropdown-list::-webkit-scrollbar-track {
    background: transparent;
}

.dropdown-list::-webkit-scrollbar-thumb {
    background: #dee2e6;
    border-radius: 4px;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
    background: #ced4da;
}

.dropdown-item {
    padding: 12px 16px;
    cursor: pointer;
    font-size: 14px;
    color: #495057;
    transition: all 0.2s ease;
}

.dropdown-item:hover {
    background: #f8f9fa;
    color: #1a1a1a;
}

.info-button {
    position: fixed;
    top: 20px;
    z-index: 1;
}

.info-button.menu-button {
    left: 20px;
}

.info-button:not(.menu-button) {
    left: 60px;
}

.info-toggle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: transparent;
    border: none;
    color: #adb5bd;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    padding: 0;
}

.info-toggle .material-icons {
    font-size: 28px;
    font-weight: 300;
}

.info-toggle:hover {
    color: #495057;
}

.info-panel {
    position: fixed;
    top: 20px;
    left: -400px;
    width: 360px;
    max-height: calc(100vh - 40px);
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
    z-index: 10;
    transition: left 0.3s ease;
    display: flex;
    flex-direction: column;
}

.info-panel.show {
    left: 20px;
}

.info-header {
    padding: 16px;
    border-bottom: 1px solid #f1f3f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info-header h2 {
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0;
}

.close-info {
    background: transparent;
    border: none;
    color: #adb5bd;
    font-size: 24px;
    cursor: pointer;
    padding: 4px;
    transition: color 0.2s ease;
}

.close-info:hover {
    color: #495057;
}

.info-content {
    padding: 16px;
    overflow-y: auto;
}

.info-section {
    margin-bottom: 20px;
}

.info-section:last-child {
    margin-bottom: 0;
}

.info-section h3 {
    font-size: 14px;
    font-weight: 500;
    color: #868e96;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.info-section p {
    font-size: 14px;
    color: #495057;
    line-height: 1.5;
    margin: 0;
}

/* Add scrollbar styles for the info panel content */
.info-content::-webkit-scrollbar {
    width: 8px;
}

.info-content::-webkit-scrollbar-track {
    background: transparent;
}

.info-content::-webkit-scrollbar-thumb {
    background: #dee2e6;
    border-radius: 4px;
}

.info-content::-webkit-scrollbar-thumb:hover {
    background: #ced4da;
}

/* Add these styles for the menu button */
.menu-button .menu-toggle,
.info-button .info-toggle {
    width: 36px !important;
    height: 36px !important;
    border-radius: 50% !important;
    background: transparent !important;
    border: none !important;
    color: #adb5bd !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: all 0.2s ease !important;
    padding: 0 !important;
}

.menu-button .menu-toggle .material-icons,
.info-button .info-toggle .material-icons {
    font-size: 28px !important;
    font-weight: 300 !important;
    color: inherit !important;
}

.menu-button .menu-toggle:hover,
.info-button .info-toggle:hover {
    color: #495057 !important;
}

/* Keep the positioning */
.menu-button {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 2;
}

.info-button {
    position: fixed;
    top: 20px;
    left: 60px;
    z-index: 1;
}

/* Even more specific selector */
.menu-button .menu-toggle.atoms-menu-button {
    width: 36px !important;
    height: 36px !important;
    border-radius: 50% !important;
    background: transparent !important;
    border: none !important;
    color: #adb5bd !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: all 0.2s ease !important;
    padding: 0 !important;
}

.menu-button .menu-toggle.atoms-menu-button .material-icons {
    font-size: 28px !important;
    font-weight: 300 !important;
    color: inherit !important;
}

.menu-button .menu-toggle.atoms-menu-button:hover {
    color: #495057 !important;
}

/* Update these styles */
.menu-button,
.info-button {
    position: fixed;
    top: 20px;
    z-index: 4;  /* Increase z-index to be above sidebar */
}

.menu-button {
    left: 20px;
}

.info-button {
    left: 60px;
}

.menu-toggle,
.info-toggle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: transparent;
    border: none;
    color: #adb5bd;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    padding: 0;
}

.menu-toggle .material-icons,
.info-toggle .material-icons {
    font-size: 28px;
    font-weight: 300;
}

.menu-toggle:hover,
.info-toggle:hover {
    color: #495057;
}

/* Add base stacking context */
#particleCanvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

/* Lower button z-index */
.info-button {
    position: fixed;
    top: 20px;
    z-index: 1;
}

/* Higher panel z-index */
.sidebar-container {
    z-index: 10;
}

.info-panel {
    z-index: 10;
}
