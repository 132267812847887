@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');


.sidebar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Full width */
    padding: 5px;
    border: none;
    background-color: var(--bg-color);
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 60px;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
  }
  
  .sidebar-button:hover {
    background-color: var(--sidebar-button-hover);
  }
  
  .sidebar-button-icon {
    margin-left: 10%;
    margin-right: 5%; /* Space between icon and text */
    flex-shrink: 0; /* Prevent icon from shrinking */
  }
  
  .button-text {
    font-size: 20px;
    color: var(--text-color);
    flex-grow: 1; /* Allow text to grow */
    text-align: left; /* Align text to the left */
    margin-left: 1vw; /* Add some left margin for spacing */
  }