.topics-graph-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #f8f9fa;
}

.topics-graph-container svg {
  width: 100%;
  height: 100%;
}

.node-rect {
  fill: white;
  stroke: none;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.1));
  transition: all 0.2s ease;
}

.node-gradient-border {
  stroke-width: 2px;
  fill: none;
  filter: blur(0.7px);
  opacity: 0.9;
}

.node-label {
  fill: #666;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  pointer-events: none;
  transition: all 0.2s ease;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.edgePath {
  stroke: #dee2e6;
  stroke-width: 1px;
  opacity: 0.6;
  fill: none;
}

.edgePath .arrowhead {
  fill: #dee2e6;
  opacity: 0.6;
}

marker#arrowhead path {
  fill: #dee2e6;
  stroke: none;
}

g.node:hover .node-rect {
  fill: #f1f3f5;
}

g.node:hover .node-label {
  fill: #495057;
}

.node {
  cursor: pointer;
}

.edgePath path {
  transition: none;
}

g.node:hover .node-gradient-border {
  opacity: 1;
  filter: blur(0.5px);
}

.node-description {
  fill: #666;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.node-description tspan {
  text-anchor: middle;
}

.node-button {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.button-background {
  fill: white;
  stroke: #dee2e6;
  stroke-width: 1px;
  transition: all 0.2s ease;
}

.button-icon {
  pointer-events: none;
}

.button-icon div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-icon svg {
  width: 20px !important;
  height: 20px !important;
  fill: #666;
}

.button-hover-area {
  cursor: pointer;
}

.node-button:hover .button-background {
  stroke: #dee2e6;
  fill: #f1f3f5;
  filter: none;
}

.node-button:hover .button-icon {
  fill: #495057;
}

.node-button:active .button-background {
  fill: #f8f9fa;
}

.node-rect, .node-gradient-border {
  transition: all 0.3s ease;
}

.node {
  transition: transform 0.3s ease;
}

.node-button:hover .button-icon svg {
  fill: #495057;
}

.button-label {
  fill: #666;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  pointer-events: none;
}

.graph-tooltip {
  display: none;
}

/* Remove hover effect for expanded nodes */
g.node[expanded="true"]:hover .node-rect {
    fill: white;  /* Keep the original color */
}

/* Keep the gradient border visible for expanded nodes */
g.node[expanded="true"]:hover .node-gradient-border {
    opacity: 0.9;
    filter: blur(0.7px);
}

/* Update button hover styles */
.node-button:hover .button-background {
    stroke: #dee2e6;  /* Keep the original border color */
    fill: #f1f3f5;  /* Darken the background like nodes do */
}

/* Remove the shadow on hover */
.node-button:hover .button-background {
    filter: none;
}

/* Keep icon color consistent with background change */
.node-button:hover .button-icon svg {
    fill: #495057;
}
