/* Components/Navbar.css */
.navbar {
    position: fixed;
    top: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: var(--bg-color);
    border-bottom: none;
    z-index: 100;
    gap: 40px;
    padding-right: 32px;
  }
  
  .navbar-link {
    color: #666;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.2s ease;
    position: relative;
  }
  
  .navbar-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 50%;
    background-color: #666;
    transform: translateX(-50%);
    transition: width 0.2s ease;
  }
  
  .navbar-link:hover {
    color: #212529;
  }
  
  .navbar-link:hover::after {
    width: 100%;
  }
  